// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-app-js": () => import("./../../src/pages/app/app.js" /* webpackChunkName: "component---src-pages-app-app-js" */),
  "component---src-pages-coupon-redeem-js": () => import("./../../src/pages/coupon-redeem.js" /* webpackChunkName: "component---src-pages-coupon-redeem-js" */),
  "component---src-pages-gift-order-js": () => import("./../../src/pages/gift-order.js" /* webpackChunkName: "component---src-pages-gift-order-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-logout-js": () => import("./../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-privacy-js": () => import("./../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-redeem-js": () => import("./../../src/pages/redeem.js" /* webpackChunkName: "component---src-pages-redeem-js" */),
  "component---src-pages-signin-js": () => import("./../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signin-verify-js": () => import("./../../src/pages/signin-verify.js" /* webpackChunkName: "component---src-pages-signin-verify-js" */),
  "component---src-pages-terms-js": () => import("./../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

